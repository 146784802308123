import React from 'react';
import Layout from '../components/layout';

export default function Books() {
  return (
    <Layout>
      <h1>Books</h1>
    </Layout>
  );
}
